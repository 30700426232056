import * as React from 'react'
import { createTheme, ThemeProvider, Box, Stack, Grid, ButtonGroup, Button, CircularProgress, TextField, MenuItem, Slider } from '@mui/material'
import FileDownload from '@mui/icons-material/FileDownload'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Stop from '@mui/icons-material/Stop'
import axios from 'axios'

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  const [voiceNameList, setVoiceNameList] = React.useState([])
  const [outputFormatList, setOutputFormatList] = React.useState([])
  const [voiceName, setVoiceName] = React.useState('')
  const [outputFormat, setOutputFormat] = React.useState('')
  const [pitch, setPitch] = React.useState(0)
  const [rate, setRate] = React.useState(0)
  const [volume, setVolume] = React.useState(0)
  const [text, setText] = React.useState('请在此输入文字。')

  const [playReady, setPlayReady] = React.useState(false)
  const [playing, setPlaying] = React.useState(false)
  const [audio, setAudio] = React.useState(null)

  const player = React.useRef(null)

  const ttsUrl = window.location.host.endsWith('yjbeetle.art') ? 'https://tts.yjbeetle.art' : 'https://tts.yjbeetle.workers.dev'

  React.useEffect(() => {
    axios(ttsUrl + '/voiceNameList').then(res => setVoiceNameList(res.data))
    axios(ttsUrl + '/outputFormatList').then(res => setOutputFormatList(res.data))
  }, [])

  React.useEffect(() => {
    setVoiceName(voiceNameList.length ? voiceNameList[0].key : '')
  }, [voiceNameList])

  React.useEffect(() => {
    setOutputFormat(outputFormatList.length ? outputFormatList[0].key : '')
  }, [outputFormatList])

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'background.default',
        paddingTop: 2
      }}>
        <Stack spacing={2} style={{ height: '100vh' }}>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="声音"
                value={voiceName}
                onChange={event => setVoiceName(event.target.value)}
              >
                {voiceNameList.map(e => (
                  <MenuItem key={e.key} value={e.key}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <div style={{ color: 'white' }}>音调</div>
              <Slider aria-label="音调" valueLabelDisplay="auto" min={-100} max={100} value={pitch} onChange={event => setPitch(event.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <div style={{ color: 'white' }}>语速</div>
              <Slider aria-label="语速" valueLabelDisplay="auto" min={-100} max={100} value={rate} onChange={event => setRate(event.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <div style={{ color: 'white' }}>音量</div>
              <Slider aria-label="音量" valueLabelDisplay="auto" min={-100} max={100} value={volume} onChange={event => setVolume(event.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="outlined-select-currency"
                select
                label="输出格式"
                value={outputFormat}
                onChange={event => setOutputFormat(event.target.value)}
              >
                {outputFormatList.map(e => (
                  <MenuItem key={e.key} value={e.key}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <ButtonGroup>
                <Button onClick={event => {
                  if (playing) {
                    player.current.pause()
                  } else {
                    setPlayReady(true)
                    axios.post(
                      ttsUrl + '/tts',
                      { text, outputFormat: 'webm-24khz-16bit-mono-opus', voiceName, pitch, rate, volume },
                      { responseType: 'blob' }
                    ).then(res => setAudio(window.URL.createObjectURL(res.data)))
                  }
                }}>
                  {playing ? <Stop /> : playReady ? <CircularProgress /> : <PlayArrow />}
                </Button>

                <Button onClick={event => {
                  axios.post(
                    ttsUrl + '/tts',
                    { text, outputFormat, voiceName, pitch, rate, volume },
                    { responseType: 'blob' }
                  ).then(res => {
                    const downloadElement = document.createElement('a')
                    const href = window.URL.createObjectURL(res.data)
                    downloadElement.style.display = 'none'
                    downloadElement.href = href
                    downloadElement.download = 'mstts_audio'
                    downloadElement.click()
                  })
                }}><FileDownload /></Button>
              </ButtonGroup>
            </Grid>

          </Grid>

          <TextField
            id="outlined-multiline-static"
            label="文本"
            multiline
            rows={20}
            value={text}
            style={{ height: '100vh' }}
            onChange={(event) => {
              setText(event.target.value);
            }}
          />

          <audio ref={player} src={audio} autoPlay
            onPlaying={event => setPlaying(true)}
            onPause={event => {
              setPlaying(false)
              setPlayReady(false)
            }}
          />

        </Stack>
      </Box>
    </ThemeProvider>
  );
}

export default App;
